// Styling for the footer
footer {
	padding: 60px 0 40px;
	border-top: 10px solid #FFF;
	text-align: center;
	@media(min-width: 768px) {

	}
	@media(min-width: 992px) {
		padding: 120px 0 80px;
	}
	h2.section-heading{
		margin-bottom: 40px;
		font-size: 50px;
		margin-top: 0;
		margin-bottom: 43px;
		text-transform: none;
	}
	a {
		color: #FFF;
		font-weight: 200;
		font-size: 20px;
		margin-bottom: 20px;
		display: inline-block;
		white-space: nowrap;
		span.fa {
			color: $blue;
			font-size: 30px;
			vertical-align: top;
			&.fa-share {
				display: inline-block;
				color: $darkblue;
				background-color: $blue;
				padding: 6px;
				border-radius: 50px;
				font-size: 20px;
				margin-top: -1px;
			}
		}
		&:hover {
			color: $blue;
			text-decoration: none;
		}
	}
}

