// Styling for the masthead
header.masthead {
  text-align: center;
  color: white;
  background-image: url('../img/header-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  position: relative;
  @include background-cover;
  .intro-text {
    padding-top: 150px;
    padding-bottom: 100px;
    .intro-lead-in {
      font-size: 22px;
      font-style: italic;
      line-height: 22px;
      margin-bottom: 25px;
      @include serif-font;
    }
    .intro-heading {
      font-size: 40px;
      font-weight: 700;
      line-height: 42px;
      margin-bottom: 25px;
      @include heading-font;
    }
  }
  .js-scroll-trigger{
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: $white;
    font-size: 32px;
  }
}
@media(min-width:768px) {
  header.masthead {
    height: 768px;
    .intro-text {
      padding-top: 300px;
      padding-bottom: 200px;
      .intro-heading {
        font-size: 50px;
        font-weight: 700;
        line-height: 1.14;
        margin-bottom: 50px;
        letter-spacing: -0.005em;
        word-spacing: -0.05em;
        @include heading-font;
      }
    }
  }
}
@media(min-width:992px) {
  header.masthead {
    height: 768px;
    .intro-text {
      padding-top: 300px;
      padding-bottom: 200px;
      .intro-heading {
        font-size: 70px;
        font-weight: 700;
        line-height: 1.14;
        margin-bottom: 50px;
        letter-spacing: -0.005em;
        word-spacing: -0.05em;
        @include heading-font;
      }
    }
  }
}
