// Styling for the navbar
#mainNav {
  background-color: rgba(0,28,98,0.85);
  .navbar-toggler {
    font-size: 18px;
    right: 0;
    color: $blue;
    border: 0;

    @include heading-font;
    .fa-bars{
      padding: 12px;
      background: #f5fafc;
      border-radius: 25px;
      font-size: 26px;
      margin-left: 8px;
      width: 49px;
      height: 49px;
      color: $darkblue;
    }
  }
  .navbar-brand {
    color: $primary;
    padding: 0;

    @include script-font;
    &.active,
    &:active,
    &:focus,
    &:hover {
      color: darken($primary, 10%);
    }
    img{
      max-width: 100%;
    }
    width: 140px;
    @media (min-width: 992px) {
      width: auto;
    }
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 20px;
        font-weight: bold;
        padding: 0.75em 0;
        color: white;
        @include heading-font;
        &.active,
        &:hover {
          color: $primary;
        }
        text-align: center;
        @media (min-width: 992px) {
          font-size: 17px;
          text-align: initial;
        }
        @media (min-width: 1200px) {
          font-size: 20px;
        }
      }
    }
  }
}
@media(min-width:992px) {
  #mainNav {
    padding-top: 25px;
    padding-bottom: 25px;
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    background-color: transparent;
    .navbar-brand {
      font-size: 1.75em;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 0.4em 0.7em !important
        }
      }
    }
    &.navbar-shrink {
      padding-top: 0;
      padding-bottom: 0;
      background-color: rgba(0,28,98,0.85);
      .navbar-brand {
        font-size: 1.25em;
        padding: 12px 0;
      }
    }
  }
}

@media(min-width:1200px) {
  #mainNav {
    padding-top: 25px;
    padding-bottom: 25px;
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    background-color: transparent;
    .navbar-brand {
      font-size: 1.75em;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 0.5em 0.84em !important
        }
      }
    }
    &.navbar-shrink {
      padding-top: 0;
      padding-bottom: 0;
      background-color: rgba(0,28,98,0.85);
      .navbar-brand {
        font-size: 1.25em;
        padding: 12px 0;
      }
    }
  }
}
