// shelf plans styling

#shelfplans {
	text-align: center;
	.flags {
		width: 125px;
		margin: 0 auto;
		cursor: pointer;
		padding-bottom: 80px;
		position: relative;
		.arrow {
			display: block;
			position: absolute;
			bottom: 15px;
			left: 50%;
			transform: translateX(-50%);
			font-size: 24px;
			opacity: 0;
			transition: opacity 0.3s ease;
		}
		.flag-header {
			font-size: 30px;
			color: $blue;
			transition: color 0.3s ease;
		}
		.flag {
			opacity: 0.2;
			transition: opacity 0.3s ease;
		}
		&.active, &:hover {
			.flag {
				opacity: 1;
			}
			.flag-header {
				color: $white;
			}
		}
		&.active {
			.arrow {
				opacity: 1;
			}
		}
	}
	.mybtn {
		display: block;
		width: 95%;
		padding: 17px;
		margin: 0 auto;
		border: 2px solid $blue;
		border-radius: 39px;
		font-size: 28px;
		color: $blue;
		font-weight: bold;
		transition: all 0.3s ease;
		background-color: transparent;
		margin-bottom: 20px;
		&:hover, &.act {
			text-decoration: none;
			background-color: $blue;
			color: $darkblue;
		}
	}
	.uk-btns {
		display: none;
	}
	.uk {
		.uk-btns {
			display: flex;
		}
		.sctotland-btns {
			display: none;
		}
	}
	.shelf-plan {
		display: none;
		.text{
			font-size: 28px;
			@include serif-font;
			font-weight: 200;
			margin: 30px 0;
			@media(min-width: 768px) {
				margin: 60px 50px;
				font-size: 24px;
			}
		}
	}
	.uk1 {
		#uk-shelf-1 {
			display: block;
		}
	}
	.uk2 {
		#uk-shelf-2 {
			display: block;
		}
	}
	.sc1 {
		#sc-shelf-1 {
			display: block;
		}
	}
	.sc2 {
		#sc-shelf-2 {
			display: block;
		}
	}
	.btns-wrap {
		margin-top: 15px;
	}
	.shelves{
		margin-top: 60px;
		@media(min-width: 768px) {
			margin-top: 110px;
		}

	}
	.img-wrapper{
		img{
			max-width: 100%;
		}
	}

}