// Styling for the carousel

.carousel-item {

	width: 100vw;
	height: auto;
	padding: 50px 30px 50px;
	min-height: calc(100vh - 85px);
	@media (min-width: 992px) {
		height: 840px;
		padding: 100px 30px 100px;
	}
	.container {
		margin-top: 120px;
	}
	.slide-img {
		width: 90%;
		margin: 0 auto;
		img {
			margin: 0 auto;
			display: block;
			max-width: 100%;
		}
	}
	.text {
		display: block;
		width: 95%;
		margin: 30px auto;
		text-align: center;
		@media(min-width: 768px) {
			width: 86%;
			margin: 50px auto;
		}
		@media(min-width: 992px) {
			width: 70%;
		}
		.slide-header {
			font-size: 24px;
			color: $darkblue;
			font-weight: bold;
			@media(min-width: 768px) {
				font-size: 28px;
			}
			@media(min-width: 992px) {
				font-size: 28px;
			}
		}
		.slide-text {
			font-size: 20px;
			color: $white;
			@media(min-width: 768px) {
				font-size: 24px;
			}
			@media(min-width: 992px) {
				font-size: 24px;
			}
		}
	}
}

#carousel2 {
	.carousel-item {

		width: 100vw;
		height: auto;
		padding: 50px 30px 50px;
		min-height: auto;
		@media (min-width: 992px) {
			height: 730px;
			padding: 100px 30px 100px;
		}
	}
	.slide-header {
		font-size: 24px;
		width: 100%;
		display: inline-block;
		@media (min-width: 992px) {
			width: 60%;
		}
	}
}