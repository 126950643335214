// Mixins
// Background Cover Mixin
@mixin background-cover {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

// Font Mixins
@mixin serif-font {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
}
@mixin script-font {
  font-family: "proxima-nova", Helvetica, Arial, cursive;
}
@mixin body-font {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
}
@mixin heading-font {
  font-family: "proxima-nova", Helvetica, Arial, sans-serif;
}
