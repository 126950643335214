// Global styling for this template
body {
	overflow-x: hidden;
	@include body-font;
	background-color: $darkblue;
	color: $white;
}

p {
	line-height: 1.75;
}

a {
	color: $primary;
	&:hover {
		color: darken($primary, 10%);
	}
}

.text-primary {
	color: $primary !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 700;
	@include heading-font;
	color: $white;
}

section {
	padding: 30px 0;
	h2.section-heading {
		font-size: 38px;
		margin-top: 0;
		margin-bottom: 30px;
		text-transform: none;
		@media(min-width: 768px) {
			font-size: 50px;
			margin-bottom: 35px;
		}
		@media(min-width: 992px) {
			font-size: 50px;
			margin-bottom: 43px;
		}
	}
	h3.section-subheading {
		font-size: 26px;
		color: $blue;
		font-weight: 100;
		font-style: italic;
		margin-bottom: 17px;
		@include serif-font;
		text-transform: uppercase;
		@media(min-width: 768px) {
			font-size: 32px;
		}
		@media(min-width: 992px) {
			font-size: 40px;
		}
	}
	h4 {
		font-size: 24px;
		font-weight: 200;
		margin-bottom: 20px;
		letter-spacing: -0.005em;
		word-spacing: -0.1em;
		@media(min-width: 768px) {
			font-size: 28px;
		}
		@media(min-width: 992px) {
			font-size: 34px;
		}
	}
	#checklist{
		margin-top: 0px;
	}
	#checklist1{
		padding-top: 60px;
		width: 1px;
	}
}

@media(min-width: 768px) {
	section {
		padding: 60px 0;
	}
}

// Button Styles
.btn {
	@include heading-font;
	font-weight: 700;
}

.btn-xl {
	font-size: 18px;
	padding: 20px 40px;
}

.btn-primary {
	background-color: $primary;
	border-color: $primary;
	&:active,
	&:focus,
	&:hover {
		background-color: darken($primary, 7.5%) !important;
		border-color: darken($primary, 7.5%) !important;
		color: white;
	}
	&:active,
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, .5) !important;
	}
}

// Highlight color customization
::-moz-selection {
	background: $primary;
	text-shadow: none;
}

::selection {
	background: $primary;
	text-shadow: none;
}

img::selection {
	background: transparent;
}

img::-moz-selection {
	background: transparent;
}

#toStock, #shelfrules {
	padding: 0;
}

section#shelfplans {
	text-align: center;
	padding-top: 80px;
}

.carousel {
	.fa {
		border-radius: 50%;
		background-color: $darkblue;
		width: 60px;
		line-height: 60px;
		font-size: 28px;
	}
}

#toStock {
	.section-heading {
		position: absolute;
		z-index: 100;
		top: 60px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		text-align: center;
	}
}

#shelfrules{
	.section-heading {
		position: absolute;
		z-index: 100;
		top: 60px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		text-align: center;
	}
}