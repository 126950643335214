// Styling for the services section
#brands{
  //background: top left url("../img/grad-back.jpg") no-repeat;
  background-image: linear-gradient(144deg,#008dcc,64%, $darkblue);
}
.mugs{
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: flex-end;
  .mug1{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
.mugs-img{
  margin-bottom: 80px;
  margin-top: 45px;
  @media(min-width: 768px) {
    margin-bottom: 100px;
  }
  @media(min-width: 992px) {
    margin-bottom: 180px;
  }
  img {
    max-width: 100%;
    transform: translateX(-1%);

  }
  .sources{
    font-size: 20px;
    font-weight: 300;
    margin-top: 30px;
  }
}
.percent{
  font-size: 28px;
  font-weight: bold;
}
.service-heading {
  margin: 15px 0;
  text-transform: none;
}

.check-item{
  &::after {
    display: block;
    clear: both;
    content: "";
  }
  margin-bottom: 40px;
  .check{
    float: left;
    margin: 0px 15px 0px 10px;
    @media (min-width: 768px) {
      margin: 0px 15px 0px 10px;
    }
    @media (min-width: 992px) {
      margin: 0px 25px 0px 40px;
    }

  }
  .check-text{
    float: left;
    width: calc(100% - 85px);
    font-size: 20px;
    line-height: 1.3;
    @media (min-width: 768px) {

    }
    @media (min-width: 992px) {
      width: calc(100% - 122px);
      padding-right: 20px;
    }
  }
}

.round-images{
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  padding-bottom: 60px;
  @media (max-width: 767px) {
    img{
      width: 75px;
      height: auto;
    }
  }
}